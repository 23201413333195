// https://atlassian-2y.sentry.io/projects/afm-search-ai/getting-started/
export const SENTRY_DSN =
	'https://467ed23c400f1de490da05364bed5930@o55978.ingest.us.sentry.io/4507432686125056';

/**
 * Copied from:
 * people-and-teams/teams-client/src/services/sentry/utils/initialise-sentry.ts÷
 *
 * The idea here is that errors are not captured by default, instead we will
 * expose an function to explicitly log errors to our Sentry project.
 */
export const DISABLED_INTEGRATIONS = [
	// Do not capture errors from onerror events
	'onerror',

	// Do not capture errors from onunhandledrejection events
	'onunhandledrejection',

	// Do not dedupe errors, this should be done by the product's Sentry client
	'Dedupe',
];

export const UNKNOWN_TAG = 'unknown';
