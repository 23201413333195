import { useCallback } from 'react';

import { type AnalyticsEventPayload, useAnalyticsEvents } from '@atlaskit/analytics-next';

import { DEFAULT_GAS_CHANNEL } from '../../../../constants';

export const useAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireAnalyticsEvent = useCallback(
		(payload: AnalyticsEventPayload) => {
			createAnalyticsEvent(payload).fire(DEFAULT_GAS_CHANNEL);
		},
		[createAnalyticsEvent],
	);

	return [fireAnalyticsEvent];
};
