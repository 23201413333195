import { getSentryClient } from '../sentry';

const error = async (error: unknown) => {
	try {
		const SentryClient = await getSentryClient();

		if (!SentryClient) {
			return;
		}

		SentryClient.withScope((scope) => {
			// TODO bring in user, product and other meta data from the to be implmented state store
			// scope.setUser({ id: user?.id || UNKNOWN_TAG });
			// scope.setTag('product', primaryProduct || UNKNOWN_TAG);
			SentryClient.captureException(error, scope);
		});
	} catch (error) {
		// When there was an error with Sentry, just output to console
		// eslint-disable-next-line no-console
		console.error(error);
	}
};

export const logger = {
	error,
};
