import type { captureException, Event, Scope } from '@sentry/browser';
import type { Integration } from '@sentry/types';

import { PRODUCT_BUILD } from '../../constants/build';
import { getEnvironment } from '../environment';

import { DISABLED_INTEGRATIONS, SENTRY_DSN, UNKNOWN_TAG } from './constants';

type SentryClient = {
	withScope(callback: (scope: Scope) => void): void;
	captureException: typeof captureException;
};

let Sentry: Promise<SentryClient | void> | undefined;

const integrations = (integrations: Integration[]) => {
	return integrations.filter((integration) => {
		return DISABLED_INTEGRATIONS.indexOf(integration.name) === -1;
	});
};

const beforeSend = (event: Event) => {
	// Delete the query string as it contains UGC
	if (event.request?.url) {
		event.request.url = event.request.url.split('?')[0];
	}

	if (event.request?.headers?.Referer) {
		event.request.headers.Referer = event.request.headers.Referer.split('?')[0];
	}

	if (event.environment === 'development') {
		return null;
	}
	return event;
};

export const getSentryClient = async () => {
	if (!Sentry) {
		Sentry = import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/search-page/sentry" */ '@sentry/browser'
		)
			.then((SentryModule) => {
				SentryModule.init({
					dsn: SENTRY_DSN,
					attachStacktrace: true,
					release: PRODUCT_BUILD || UNKNOWN_TAG,
					environment: getEnvironment(),
					integrations,
					beforeSend,
				});

				return SentryModule;
			})
			.catch(() => {
				// TODO: Retry mechanism for chunk loading failures?
			});
	}

	return Sentry;
};
