import React, {
	type ComponentProps,
	type ComponentType,
	type PropsWithChildren,
	useCallback,
} from 'react';

import { useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { ErrorBoundary as CommonErrorBoundary } from '@atlassian/search-common';
import { SearchDialog } from '@atlassian/search-dialog';

import { ReadingAidsContainer, ReadingAidsPopupContainer } from '../ai-answer-dialog/styled';
import { logger } from '../common';
import { DEFAULT_GAS_CHANNEL } from '../constants';

import { messages } from './messages';
import { ErrorBoundaryFallbackText } from './styled';

type ErrorBoundaryProps = ComponentProps<'div'> & {
	isReadingAids?: boolean;
	wrapper?: ComponentType<PropsWithChildren<{}>>;
	source?: string;
};

/**
 *	@private
 *	@deprecated Use the ErrorBoundary in common instead
 *	TODO Rewrite to use the error boundry from common
 */
export const ErrorBoundary = ({ isReadingAids, wrapper, source, children }: ErrorBoundaryProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const DefaultWrapper = isReadingAids
		? (props: { children: React.ReactNode }) => (
				<ReadingAidsPopupContainer>
					<ReadingAidsContainer>{props.children}</ReadingAidsContainer>
				</ReadingAidsPopupContainer>
			)
		: SearchDialog;
	const Wrapper = wrapper ? wrapper : DefaultWrapper;

	const logError = useCallback(
		(error: Error) => {
			// Currently we are in between deprecating UFOv1 and adopting react-UFO  for platform packages, so this is a temporary way for us to collect error logs
			// https://atlassian.slack.com/archives/C01AY8132S3/p1716509785878689
			const errorAttributes = {
				errorName: error.name,
				errorMessage: error.message,
				errorStack: error.stack?.split('\n').at(1),
			};

			createAnalyticsEvent({
				action: 'failed',
				actionSubject: 'aiAnswerDialog',
				source: source || 'unknown',
				eventType: 'track',
				attributes: errorAttributes,
			}).fire(DEFAULT_GAS_CHANNEL);

			logger.error(errorAttributes);
		},
		[source, createAnalyticsEvent],
	);

	return (
		<CommonErrorBoundary
			fallback={
				<Wrapper>
					<ErrorBoundaryFallbackText isReadingAids={isReadingAids}>
						{formatMessage(messages.error_boundary_fallback_text)}
					</ErrorBoundaryFallbackText>
				</Wrapper>
			}
			onException={logError}
		>
			{children}
		</CommonErrorBoundary>
	);
};
