import React, { type PropsWithChildren, useCallback } from 'react';

import { ErrorBoundary as CommonErrorBoundary } from '@atlassian/search-common';

import { logger } from '../../utils';

const useLogException = () => {
	return useCallback(async (error: Error) => {
		logger.error(error);
	}, []);
};

type ErrorBoundaryProps = {
	fallback?: React.ReactNode;
};

export const ErrorBoundary = (props: PropsWithChildren<ErrorBoundaryProps>) => {
	const onException = useLogException();

	return (
		<CommonErrorBoundary {...props} onException={onException}>
			{props.children}
		</CommonErrorBoundary>
	);
};
