import React from 'react';

import Button from '@atlaskit/button';
import { IconTile } from '@atlaskit/icon';
import BookWithBookmarkIcon from '@atlaskit/icon/core/book-with-bookmark';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

interface KnowledgeBadgeProps {
	name: string;
	url: string;
	onBadgeClick?: () => void;
}

const knowledgeBadgeStyles = xcss({
	display: 'inline-flex',
	border: `1px ${token('color.border')} solid`,
	borderRadius: 'border.radius.100',
	backgroundColor: 'color.background.input',
});

const buttonLinkStyles = xcss({
	padding: 'space.025',
	marginLeft: 'space.025',
	marginRight: 'space.050',
});

export const KnowledgeBadge = ({ name, url, onBadgeClick = () => {} }: KnowledgeBadgeProps) => (
	<Box xcss={knowledgeBadgeStyles}>
		<Button
			href={url}
			appearance="subtle"
			onClick={() => {
				onBadgeClick();
			}}
			spacing="none"
			target="_blank"
		>
			<Inline space="space.050" alignBlock="center" xcss={buttonLinkStyles}>
				<IconTile icon={BookWithBookmarkIcon} label="" appearance="blue" size="16" />
				<Text>{name}</Text>
			</Inline>
		</Button>
	</Box>
);
