export const RelatedEntitiesQuery = `
  query RelatedEntities(
    $entityId: String!
    $entityType: KnowledgeDiscoveryEntityType!
    $relatedEntityType: KnowledgeDiscoveryEntityType!
    $cloudId: String!
    $first: Int
    $after: String
  ) {
    knowledgeDiscovery {
      relatedEntities(
        entityId: $entityId
        entityType: $entityType
        relatedEntityType: $relatedEntityType
        cloudId: $cloudId
        first: $first
        after: $after
      ) @optIn(to: "KnowledgeDiscovery Get Related Entities") {
        ... on KnowledgeDiscoveryRelatedEntityConnection {
          __typename
          nodes {
            ... on KnowledgeDiscoveryTopic {
              id
              name
              description
              documentCount
              updatedAt
              type
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
        }
        ... on QueryError {
          message
        }
      }
    }
  }
`;
