type EnvironmentType = 'development' | 'staging' | 'production';

export const getEnvironment = (): EnvironmentType => {
	if (window.location.hostname.includes('localhost')) {
		return 'development';
	} else if (
		window.location.hostname.includes('jira-dev.com') ||
		window.location.hostname.includes('stg.atlassian.com')
	) {
		return 'staging';
	} else {
		return 'production';
	}
};
