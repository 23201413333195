/* eslint-disable @repo/internal/react/no-class-components */
import React, { type ErrorInfo, type PropsWithChildren } from 'react';

type ErrorBoundaryState = {
	error?: Error;
};

type ErrorBoundaryProps = {
	fallback?: React.ReactNode;
	onException: (error: Error) => void;
};

/**
 * Reusable error boundary for search components.
 *
 * As of React 16, there is no alternative to class components for error
 * boundaries.
 */
export class ErrorBoundary extends React.Component<
	PropsWithChildren<ErrorBoundaryProps>,
	ErrorBoundaryState
> {
	constructor(props: React.PropsWithChildren<ErrorBoundaryProps>) {
		super(props);

		this.state = { error: undefined };
	}

	static getDerivedStateFromError(error: Error) {
		return { error };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.props.onException(error);
	}

	render() {
		if (this.state.error) {
			return this.props.fallback !== undefined ? this.props.fallback : null;
		}

		return this.props.children;
	}
}
