import { useCallback, useState } from 'react';

import { deleteSessionItem, getSessionItem, setSessionItem } from '@atlassian/search-dialog';

import {
	fetchRelatedEntities,
	RelatedEntitiesResult,
	RelatedEntityType,
} from '../related-entities-api';
import { type RelatedEntityNode } from '../related-entities-api/types';

export const AI_TOPICS_CACHE_KEY = 'atlassian.search-dialog.ai-topics-component';

type AITopicsArguments = {
	entityId: string;
	cloudId: string;
	principalEntityType: RelatedEntityType;
};

export const useRelatedTopics = () => {
	const [aiRelatedTopics, setAIRelatedTopics] = useState<RelatedEntityNode[]>([]);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const fetchRelatedTopics = async ({
		entityId,
		cloudId,
		principalEntityType,
	}: AITopicsArguments) => {
		if (!entityId) {
			setAIRelatedTopics([]);
			return;
		}

		const cache = JSON.parse(getSessionItem(AI_TOPICS_CACHE_KEY) || '{}');

		if (cache?.entityId === entityId) {
			setAIRelatedTopics(cache.topics);
		} else {
			setAIRelatedTopics([]);
			deleteSessionItem(AI_TOPICS_CACHE_KEY);
			setIsLoading(true);

			try {
				const { data } = await fetchRelatedEntities({
					entityId,
					entityType: principalEntityType,
					relatedEntityType: RelatedEntityType.TOPIC,
					cloudId,
					first: 5,
				});

				if (data.knowledgeDiscovery.__typename === RelatedEntitiesResult.QueryError) {
					setError('REQUEST_ERROR');
				} else if (data.knowledgeDiscovery.relatedEntities?.nodes) {
					const entities = data.knowledgeDiscovery.relatedEntities.nodes;

					setAIRelatedTopics(entities);
					setSessionItem(AI_TOPICS_CACHE_KEY, JSON.stringify({ entityId, topics: entities }));
				}
			} catch {
				setError('NETWORK_ERROR');
			} finally {
				setIsLoading(false);
			}
		}
	};

	const resetTopics = useCallback(() => {
		setAIRelatedTopics([]);
	}, [setAIRelatedTopics]);

	const showTopics = !isLoading && !error && aiRelatedTopics.length > 0;

	return {
		aiRelatedTopics,
		fetchRelatedTopics,
		showTopics,
		resetTopics,
	};
};
