import { type RequestServiceOptions, utils } from '@atlaskit/util-service-support';

import { DEFAULT_GRAPHQL_ENDPOINT } from '../constants';

import { RelatedEntitiesQuery } from './RelatedEntitiesQuery';
import { type RelatedEntitiesResponse, type RelatedEntitiesVariables } from './types';

export const fetchRelatedEntities = (
	variables: RelatedEntitiesVariables,
): Promise<RelatedEntitiesResponse> => {
	const options: RequestServiceOptions = {
		requestInit: {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: RelatedEntitiesQuery,
				variables,
			}),
		},
		queryParams: {
			q: 'RelatedEntities',
		},
	};

	return utils.requestService<RelatedEntitiesResponse>({ url: DEFAULT_GRAPHQL_ENDPOINT }, options);
};
