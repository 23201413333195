import { useEffect, useState } from 'react';

const BUTTON_WIDTH = 20;
const PADDING_BLOCK_END = 16;
const TOPIC_INLINE_SPACE = 8;

export const useTopicBreakElems = (
	wrapperRef: React.RefObject<HTMLDivElement>,
	showTopics: boolean,
) => {
	const [breakElems, setBreakElems] = useState<number>(-1);
	const [topicLengths, setTopicLengths] = useState<number[]>([]);

	useEffect(() => {
		const observer = new ResizeObserver((topics) => {
			const wrapper = topics[0];
			const cwidth = wrapper.target.clientWidth;
			const tl = topicLengths;
			var runningWidth = 0;
			var elemsHold = 0;

			// on first run, all topics are displayed so we can loop through and get the lengths
			if (tl.length === 0) {
				for (const topic of Array.from(wrapper.target.children)) {
					const width = topic.scrollWidth;
					tl.push(width);
				}
			}
			for (let i = 0; i < tl.length; i++) {
				const width = topicLengths[i];
				if (runningWidth + width + PADDING_BLOCK_END + BUTTON_WIDTH <= cwidth) {
					elemsHold += 1;
				}
				runningWidth += width + TOPIC_INLINE_SPACE;
			}
			setBreakElems(elemsHold);
			// on first run, save the topic lengths so we can reuse
			if (topicLengths.length === 0) {
				setTopicLengths(tl);
			}
		});

		if (wrapperRef.current) {
			observer.observe(wrapperRef.current);
		}

		return () => {
			observer.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showTopics]);

	return { breakElems };
};
