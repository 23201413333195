export type RelatedEntitiesVariables = {
	entityId: string;
	entityType: RelatedEntityType;
	relatedEntityType: RelatedEntityType;
	cloudId: string;
	first?: number;
	after?: string;
};

export type RelatedEntityNode = {
	id: string;
	name: string;
	description: string;
	documentCount: number;
	updatedAt: string;
	type: string;
};

type RelatedEntitiesPageInfo = {
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	startCursor?: string;
	endCursor?: string;
};

export enum RelatedEntitiesResult {
	KnowledgeDiscoveryRelatedEntityConnection = 'KnowledgeDiscoveryRelatedEntityConnection',
	QueryError = 'QueryError',
}

export type RelatedEntitiesResponse = {
	data: {
		knowledgeDiscovery: {
			__typename: RelatedEntitiesResult;
			relatedEntities?: {
				nodes: RelatedEntityNode[];
				pageInfo: RelatedEntitiesPageInfo;
			};
			message?: string;
		};
	};
};

export enum RelatedEntityType {
	KEY_PHRASE = 'KEY_PHRASE',
	TOPIC = 'TOPIC',
	USER = 'USER',
	CONFLUENCE_DOCUMENT = 'CONFLUENCE_DOCUMENT',
	CONFLUENCE_PAGE = 'CONFLUENCE_PAGE',
	CONFLUENCE_BLOGPOST = 'CONFLUENCE_BLOGPOST',
}
